import React, { useState } from "react";
import {
  Wrapper,
  MenuTextButton,
  MenuTextLink,
  MenuWrapper,
  MenuSocialButton,
  MenuButton,
} from "./index.styled";
import { useHeaderColor } from "context";
import { useStaticQuery, graphql } from "gatsby";
import { CSSTransition } from "react-transition-group";
import Menu from "./menu";

const Header = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      menuContent {
        content {
          copyright
          legalLinks {
            text
            url
          }
          mainList {
            subItems {
              text
              url
              anchor
              divider
            }
            text
            url
          }
          secondList {
            url
            text
          }
          socialLinks {
            link
            text
          }
        }
      }
    }
  `);
  const { headerState, setHeaderState } = useHeaderColor();
  const content = data.menuContent.content[0];

  return (
    <>
      <Wrapper className={className} $state={headerState}>
        <div></div>
        <MenuWrapper>
          {/* <MenuTextButton type="button">watch the FILM</MenuTextButton> */}
          <MenuTextLink to="/contact">CONTACT</MenuTextLink>
          <MenuSocialButton
            target="_blank"
            href={content.socialLinks[0].link}
          />
          <MenuButton onClick={toggleMenu} />
        </MenuWrapper>
        <CSSTransition
          in={isOpen}
          timeout={300}
          classNames="menu"
          unmountOnExit
        >
          <Menu data={content} onClick={toggleMenu} />
        </CSSTransition>
      </Wrapper>
    </>
  );
};

export default Header;
